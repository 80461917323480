<template>
    <el-dialog title="选择运营" :visible.sync="isShow" width="420px" @opened="onOpened" :before-close="onClose">
        <el-form v-if="formData" ref="formData" :model="formData" :rules="rules" label-width="80px">
            <el-form-item label="分配方式" prop="assignType">
                <el-select v-model="formData.assignType" disabled placeholder="请选择">
                    <el-option value="auto" label="自动分配" />
                    <el-option value="manual" label="指定分配" />
                    <el-option value="transfer" label="离职交接" />
                </el-select>
            </el-form-item>

            <el-form-item label="运营" prop="staffId" v-if="formData.assignType !== 'auto'">
                <el-select v-model="formData.staffId" filterable placeholder="请选择">
                    <el-option v-for="item in staffList" :key="item.id" :value="item.id" :label="item.name">
                        <StaffName :staff="item" />
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { oppoAssign } from '@/js/api/saleApi.js'
import { getStaffList } from '@/js/api/staffApi.js'

export default {
    props: ['isShow', 'activeItem'],
    data() {
        return {
            formData: null,
            rules: {
                staffId: [{ required: true, message: '请选择', trigger: 'blur' }],
            },
            staffList: [],
        }
    },
    methods: {
        onOpened() {
            const formData = {
                assignType: 'manual',
                staffId: '',
            }

            this.formData = formData
            this.getStaffListApi()
        },
        onClose() {
            this.formData = null
            this.$emit('onClose')
        },

        getStaffListApi() {
            getStaffList({}).then((res) => {
                this.staffList = res.data
            })
        },

        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    oppoAssign({ subIds: [this.activeItem.subId], ...this.formData }).then((res) => {
                        const _aNum = res.data.assigned.length
                        if (_aNum) {
                            this.$message.success('成功分配')
                        } else {
                            this.$message.error('分配失败')
                        }
                        this.$emit('onClose', true)
                        this.onClose()
                    })
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
.oppo_container {
    display: flex;
    align-items: center;
    .num {
        color: red;
        margin: 0 12px;
    }
}
.el-select {
    width: 100%;
}
</style>
