<template>
    <div class="page_container">
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item">
                    <span class="condition_content today_deal">
                        <el-checkbox-group v-model="todayDeal">
                            <el-checkbox-button :label="1">今日处理</el-checkbox-button>
                        </el-checkbox-group>
                        <el-select v-model="dealType" clearable placeholder="请选择" size="mini">
                            <el-option label="未处理" :value="2" />
                            <el-option label="未加微" :value="5" />
                            <el-option label="加微申请中" :value="3" />
                            <el-option label="已加微信" :value="4" />
                        </el-select>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">项目</span>
                    <span class="condition_content">
                        <ProjectSelect @change="onProjectNoChange" />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">投放渠道</span>
                    <span class="condition_content">
                        <el-cascader
                            v-model="providerNos"
                            :options="providerCascader"
                            placeholder="投放渠道"
                            collapse-tags
                            filterable
                            clearable
                            :props="{ expandTrigger: 'hover', multiple: true }"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">分配时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            v-model="belongTimes"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">名片类型</span>
                    <span class="condition_content">
                        <el-select v-model="oppoType" clearable placeholder="请选择">
                            <el-option
                                v-for="item in oppoTypeList"
                                :key="item.val"
                                :label="item.name"
                                :value="item.val"
                            />
                        </el-select>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">最近表单</span>
                    <span class="condition_content">
                        <el-date-picker
                            v-model="lastFormTimes"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                        />
                    </span>
                </div>
                <div class="condition_item attend_course">
                    <span class="condition_content">
                        <el-checkbox-group
                            v-model="attendType"
                            style="width: 250px; flex-shrink: 0"
                            @change="onAttendTypeChange"
                        >
                            <el-checkbox-button label="online">在线</el-checkbox-button>
                            <el-checkbox-button label="duration">出勤1</el-checkbox-button>
                            <el-checkbox-button label="dTime">出勤2</el-checkbox-button>
                            <el-checkbox-button label="unAttend">未出勤</el-checkbox-button>
                        </el-checkbox-group>
                        <el-select
                            v-if="['online', 'duration', 'unAttend'].includes(attendType[0]) || attendType.length === 0"
                            v-model="attendCourse"
                            filterable
                            :placeholder="attendType.length !== 0 ? '选择课程' : '先选择在线/出勤'"
                            :disabled="attendType.length === 0"
                            style="flex-grow: 1"
                        >
                            <template v-for="item in attendCourseList">
                                <el-option-group
                                    v-if="attendType[0] !== 'online' || item.hasTodayCourse"
                                    :key="`pId${item.pId}`"
                                    :label="item.title"
                                >
                                    <template v-for="c in item.cList">
                                        <el-option
                                            v-if="attendType[0] !== 'online' || c.isToday"
                                            :key="`course${c.id}`"
                                            :value="c.id"
                                            :label="`${c.time} ${c.title}`"
                                        />
                                    </template>
                                </el-option-group>
                            </template>
                        </el-select>
                        <el-select
                            v-if="attendType[0] === 'online'"
                            v-model="onlineStatus"
                            class="other_select"
                            :placeholder="attendCourse ? '在线状态' : '先选择课程'"
                            :disabled="!attendCourse"
                        >
                            <el-option value="ONLINE" label="在线" />
                            <el-option value="ATTENDED" label="出勤过" />
                            <el-option value="UNATTEND" label="无出勤" />
                        </el-select>
                        <template v-if="attendType[0] === 'dTime'">
                            <el-date-picker
                                :clearable="false"
                                v-model="attendTimes"
                                value-format="yyyy-MM-dd"
                                type="daterange"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                            />
                            <el-select style="width: 90px; flex-shrink: 0" v-model="attendCourseType">
                                <el-option label="直播" :value="1"></el-option>
                                <el-option label="重播" :value="2"></el-option>
                            </el-select>
                        </template>
                        <div v-if="['duration', 'dTime'].includes(attendType[0])" class="attend_duration">
                            <el-input-number
                                :disabled="!attendCourse && attendTimes.length === 0"
                                v-model="courseDuration[0]"
                                :controls="false"
                                :min="0"
                                placeholder="<时长(分)"
                                :precision="0"
                            />
                            <span>-</span>
                            <el-input-number
                                :disabled="!attendCourse && attendTimes.length === 0"
                                v-model="courseDuration[1]"
                                :controls="false"
                                :min="0"
                                placeholder="时长(分)≤"
                                :precision="0"
                            />
                        </div>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">付费状态</span>
                    <span class="condition_content">
                        <el-select v-model="paidFlag" clearable placeholder="请选择">
                            <el-option :value="true" label="已付费" />
                            <el-option :value="false" label="未付费" />
                        </el-select>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_content">
                        <el-input
                            placeholder="请输入内容"
                            @change="onUserIdChange"
                            clearable
                            v-model.trim="queryTypeValue"
                            @keyup.enter.native="onPaginationChange('page', 1)"
                        >
                            <el-select
                                class="query_type"
                                @change="onQuerySelectChange"
                                v-model="queryType"
                                slot="prepend"
                                placeholder="请选择"
                            >
                                <el-option label="联系方式" value="contact"></el-option>
                                <el-option label="微信昵称" value="nickname"></el-option>
                                <el-option label="机会ID" value="oppoIds"></el-option>
                                <el-option label="学员ID" value="userId"></el-option>
                                <el-option label="备注" value="alias"></el-option>
                            </el-select>
                        </el-input>
                    </span>
                </div>

                <div class="condition_item">
                    <span class="condition_label">意向类型</span>
                    <span class="condition_content">
                        <el-select v-model="intentType" clearable placeholder="请选择">
                            <el-option
                                v-for="item in intentTypeList"
                                :key="item.taskNo"
                                :value="item.taskNo"
                                :label="item.taskName"
                            />
                        </el-select>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">意向时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            v-model="intentTimes"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_content express">
                        <el-select v-model="expressQuery" @change="onExpressQueryChange" style="width: 140px">
                            <el-option
                                v-for="item in expressQueryList"
                                :key="item.val"
                                :value="item.val"
                                :label="item.name"
                            />
                        </el-select>
                        <el-select
                            v-if="expressQuery === 'status'"
                            v-model="expressStatus"
                            clearable
                            placeholder="请选择快递状态"
                        >
                            <el-option value="UNCOLLECT" label="未填写收货信息" />
                            <el-option value="UNSEND" label="未发货" />
                            <el-option value="SENT" label="已发货" />
                        </el-select>
                        <template v-else>
                            <el-input
                                v-for="i in expressQueryList"
                                :key="i.val"
                                v-show="i.val === expressQuery"
                                :placeholder="`请输入${i.name}`"
                                clearable
                                v-model.trim="expressValue"
                                @keyup.enter.native="onPaginationChange('page', 1)"
                            />
                        </template>
                    </span>
                </div>

                <div class="condition_item" v-if="manageGroupList.length !== 0">
                    <span class="condition_label">运营</span>
                    <span class="condition_content">
                        <StaffCascader
                            ref="staffCascader"
                            :manageGroupList="manageGroupList"
                            :multiple="true"
                            :clearable="true"
                            @change="(val) => (staffIds = val)"
                        />
                    </span>
                </div>
            </div>
            <div class="opt_container">
                <el-button plain @click="onReset">重置</el-button>
                <el-button type="primary" @click="onPaginationChange('page', 1)">查询</el-button>
            </div>
        </div>
        <el-table :data="tableData" stripe border>
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column align="center" prop="id" width="80" label="机会ID" />
            <el-table-column align="center" width="100" label="处理状态">
                <template slot-scope="scope">
                    <Tag :color="scope.row.dealFlag ? '#67C23A' : '#909399'">
                        {{ scope.row.dealFlag ? '已处理' : '未处理' }}
                    </Tag>
                </template>
            </el-table-column>
            <el-table-column align="center" width="100" label="加微状态">
                <template slot-scope="scope">
                    <span v-if="!scope.row.friendState">-</span>
                    <Tag v-else-if="scope.row.friendState === 'FRIEND'" color="#67C23A"> 已加微 </Tag>
                    <Tag v-else-if="scope.row.friendState === 'APPLY'" color="#909399"> 申请中 </Tag>
                </template>
            </el-table-column>
            <el-table-column align="center" width="100" label="微信昵称">
                <template slot-scope="scope">
                    <span v-if="scope.row.nickname">{{ scope.row.nickname }}</span>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column align="center" width="80" label="微信头像">
                <template slot-scope="scope">
                    <el-image style="width: 42px" v-if="scope.row.avatar" :src="scope.row.avatar" fit="cover" />
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column align="center" width="120" label="联系方式">
                <template slot-scope="scope">
                    <div
                        @click="onCopyContact(i.contact)"
                        class="contact_list"
                        v-for="i in scope.row.contactList"
                        :key="i.contact"
                    >
                        <span>{{ i.contact }}</span>
                        <i class="el-icon-document-copy" />
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="lastAssignTime" width="96" label="分配时间" />
            <el-table-column header-align="center" prop="alias" min-width="160" label="备注" />
            <el-table-column label="出勤课程" width="260px">
                <template slot-scope="scope">
                    <div class="course_list" v-if="scope.row.courseAttends && scope.row.courseAttends.length !== 0">
                        <div v-for="item in scope.row.courseAttends" :key="`course${item.id}`">
                            <el-tooltip placement="top" :content="item.title">
                                <span>
                                    {{ item.vType === 1 ? '直播' : '重播' }}【{{ item.courseId }}】{{ item.title }}
                                </span>
                            </el-tooltip>
                            <span>{{ item.durationText }}</span>
                        </div>
                    </div>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column header-align="center" label="意向" min-width="300">
                <template slot-scope="scope">
                    <div v-if="scope.row.intents && scope.row.intents.length !== 0 && intentTypeMap">
                        <template v-for="intent in scope.row.intents">
                            <Tag
                                v-if="intentTypeMap[intent]"
                                :key="`intents${intent}`"
                                :color="intentTypeMap[intent]['color']"
                                style="margin: 0 6px 4px 0"
                            >
                                {{ intentTypeMap[intent]['name'] }}
                            </Tag>
                            <Tag :key="`intents${intent}`" v-else color="#aaaaaa">未知</Tag>
                        </template>
                    </div>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column header-align="center" label="报名课程" min-width="200">
                <template slot-scope="scope">
                    <div v-if="scope.row.receivePackages && scope.row.receivePackages.length !== 0">
                        <div v-for="item in scope.row.receivePackages" :key="`package${item.id}`">
                            {{ item.title }}
                        </div>
                    </div>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column align="center" width="72" label="操作" fixed="right">
                <template slot-scope="scope">
                    <el-link type="primary" @click="onShowDetailDrawer(scope.row)">详情</el-link>
                    <el-link
                        v-if="scope.row.transferState && selfFlag"
                        type="warning"
                        @click="onShowEditOppoStaff(scope.row)"
                        :disabled="!scope.row.transferState"
                    >
                        转走
                    </el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            background
            @size-change="(val) => onPaginationChange('size', val)"
            @current-change="(val) => onPaginationChange('page', val)"
            :current-page.sync="page"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        />

        <DetailDrawer
            :isShow="isShowDetailDrawer"
            :activeItem="activeItem"
            :intentTypeMap="intentTypeMap"
            @onClose="onCloseDetailDrawer"
            @pageDown="onPageDown"
        />

        <EditOppoStaff :isShow="isShowEditOppoStaff" :activeItem="activeItem" @onClose="onCloseEditOppoStaff" />
    </div>
</template>
<script>
import dayjs from 'dayjs'
import { getProviderCategoryData } from '@/js/api/advertApi.js'
import { getPulicCoursePackageList } from '@/js/api/courseApi.js'
import { getStaffGroupList } from '@/js/api/staffApi.js'
import { getSystemConfig } from '@/js/api/commonApi.js'
import { getIntentTypeList, getOppoList, oppoContactList } from '@/js/api/saleApi.js'
import { filterQueryEmpty, getAttendDurationText, isUserId, generateColorFromString, isEmptyArray } from '@/js/utils.js'
import DetailDrawer from './components/tel/DetailDrawer.vue'
import EditOppoStaff from './components/tel/EditOppoStaff.vue'
import StaffCascader from '@/views/components/StaffCascader'
import ProjectSelect from '@/views/components/ProjectSelect'
import { mapGetters } from 'vuex'

let _localStorageKey = 'workbench_tel_queryType'
export default {
    computed: { ...mapGetters(['userInfo']) },
    components: { StaffCascader, ProjectSelect, DetailDrawer, EditOppoStaff },
    data() {
        return {
            providerCascader: [],
            providerNos: [],
            // 今日处理&处理状态
            todayDeal: [],
            dealType: '',
            // 分配时间
            belongTimes: [],
            // 名片类型
            oppoType: '',
            oppoTypeList: [
                { name: '新名片', val: 'new' },
                { name: '离职交接', val: 'transfer' },
                { name: '重复表单', val: 'repeat' },
            ],
            lastFormTimes: [],
            // 意向类型&意向时间
            intentType: '',
            intentTypeList: [],
            intentTypeMap: null,
            intentTimes: [],
            // 出勤课程&时长
            attendType: [],
            attendCourse: '',
            attendCourseList: [],
            courseDuration: [0, 1],
            attendTimes: [],
            attendCourseType: 1,
            onlineStatus: 'ONLINE',

            // 指定学员信息查询&标签查询
            queryTypeValue: '',
            queryType: '',

            staffIds: [],
            manageGroupList: [],
            paidFlag: '',
            expressQuery: 'status',
            expressQueryList: [
                { name: '快递状态', val: 'status' },
                { name: '快递单号', val: 'expressNo' },
                { name: '收货人姓名', val: 'receiveName' },
                { name: '收货人手机号', val: 'receiveMobile' },
                { name: '直播带货订单号', val: 'shopOrderNo' },
            ],
            expressStatus: '',
            expressValue: '',

            selfFlag: false,
            tableData: [],
            page: 1,
            pageSize: 10,
            total: 0,

            activeItem: null,
            isShowDetailDrawer: false,
            isShowEditOppoStaff: false,
        }
    },
    created() {
        if (this.$route.query.today) {
            const n = parseInt(this.$route.query.today)
            this._dealType = n != 1 ? n : ''
            this._todayDeal = [1]
            this._oppoType = this.$route.query.oppoType || ''
        }

        this.queryType = localStorage.getItem(_localStorageKey) || 'contact'

        this.getPulicCoursePackageListApi()
        this.getIntentTypeListApi()
        this.getStaffGroupData()
        this.getProviderCategoryDataApi()
        this.getSelfFlag()
    },
    methods: {
        onReset() {
            this.providerNos = []
            this.todayDeal = []
            this.dealType = ''
            this.belongTimes = []
            this.oppoType = ''
            this.lastFormTimes = []
            this.intentType = ''
            this.intentTimes = []
            this.attendType = []
            this.courseDuration = [0, 1]
            this.onlineStatus = 'ONLINE'
            this.attendCourse = ''
            this.attendTimes = []
            this.attendCourseType = 1
            this.queryTypeValue = ''
            this.paidFlag = ''
            this.staffIds = []
            this.expressStatus = ''
            this.expressValue = ''

            if (this._dealType) {
                this.dealType = this._dealType
                this._dealType = null
            }
            if (this._todayDeal) {
                this.todayDeal = this._todayDeal
                this._todayDeal = null
            }
            if (this._oppoType) {
                this.oppoType = this._oppoType
                this._oppoType = null
            }

            if (this.$refs.staffCascader) {
                this.$refs.staffCascader.onClear()
            }
            this.onPaginationChange('page', 1)
        },
        onProjectNoChange(val) {
            this.projectNo = val
            this.onReset()
        },

        onUserIdChange() {
            if (this.queryType === 'userId' && this.queryTypeValue && !isUserId(this.queryTypeValue)) {
                this.queryTypeValue = ''
                this.$message.warning('请输入正确的 学员ID')
            } else if (this.queryType === 'oppoIds' && this.queryTypeValue && !isUserId(this.queryTypeValue)) {
                this.queryTypeValue = ''
                this.$message.warning('请输入正确的 机会ID')
            }
        },
        onQuerySelectChange() {
            localStorage.setItem(_localStorageKey, this.queryType)
            this.onUserIdChange()
        },

        onExpressQueryChange() {
            this.expressStatus = ''
            this.expressValue = ''
        },

        onAttendTypeChange() {
            this.attendCourse = ''
            if (this.attendType.length > 1) {
                this.attendType.shift()
            }
            this.attendCourse = ''
            this.courseDuration = []
            this.attendTimes = []
            this.attendCourseType = 1
            this.onlineStatus = 'ONLINE'
        },

        // 复制手机号
        onCopyContact(val) {
            window.navigator.clipboard.writeText(val).then(() => {
                this.$message.success('复制成功')
            })
        },

        // 机会详情
        onShowDetailDrawer(item) {
            this.activeItem = item
            this.isShowDetailDrawer = true
        },
        onCloseDetailDrawer(state) {
            this.activeItem = null
            this.isShowDetailDrawer = false
            if (state) this.getOppoListApi()
        },

        // 分配机会
        onShowEditOppoStaff(item) {
            this.activeItem = item
            this.isShowEditOppoStaff = true
        },
        onCloseEditOppoStaff(state) {
            this.activeItem = null
            this.isShowEditOppoStaff = false
            if (state) this.getOppoListApi()
        },

        onPageDown(type) {
            let _index = 0
            this.tableData.forEach((item, index) => {
                if (item.id === this.activeItem.id) {
                    _index = index
                }
            })
            this.activeItem = null
            // 设置下一个
            let _item = this.tableData[_index + 1]
            if (type === 'prev') {
                _item = this.tableData[_index - 1]
            }
            if (_item) {
                this.$nextTick(() => {
                    this.activeItem = _item
                })
            }
        },

        onPaginationChange(type, value) {
            if (type === 'size') {
                this.pageSize = value
                this.page = 1
            } else {
                this.page = value
            }
            this.$nextTick(this.getOppoListApi)
        },
        async getOppoListApi() {
            let params = {
                projectNo: this.projectNo,
                page: this.page,
                size: this.pageSize,
                showPage: true,
                orderBy: '-id',
                paidFlag: this.paidFlag,
                shouldContact: this.todayDeal.length !== 0 ? true : '',
                oppoType: this.oppoType,
            }

            if (this.userInfo.isAdmin) {
                // 管理员
                params.staffIds = !isEmptyArray(this.staffIds) ? this.staffIds : ''
            } else if (this.manageGroupList.length !== 0) {
                // 主管
                if (isEmptyArray(this.staffIds)) {
                    params.groupIds = this.manageGroupList.map((i) => i.id)
                } else {
                    params.staffIds = this.staffIds
                }
            } else {
                // 员工
                params.staffIds = [this.userInfo.id]
            }

            // 未处理/加微中/已加微
            if (this.dealType === 2) {
                params.shouldContactType = 'not_contact'
            } else if (this.dealType === 3) {
                params.friendState = 'APPLY'
            } else if (this.dealType === 4) {
                params.friendState = 'FRIEND'
            } else if (this.dealType === 5) {
                params.friendState = 'NONE'
            }
            params.providerNos = !isEmptyArray(this.providerNos) ? this.providerNos.map((item) => item[1]) : null
            params[this.queryType] = this.queryTypeValue
            if (this.queryType === 'oppoIds' && this.queryTypeValue) params[this.queryType] = [this.queryTypeValue]

            params.belongTimes = !isEmptyArray(this.belongTimes) ? this.belongTimes : null
            params.lastFormTimes = !isEmptyArray(this.lastFormTimes) ? this.lastFormTimes : null

            // 意向
            if (this.intentType || !isEmptyArray(this.intentTimes)) {
                params.intent = filterQueryEmpty({
                    type: this.intentType,
                    intentTimes: !isEmptyArray(this.intentTimes) ? this.intentTimes : '',
                })
            }

            // 出勤
            if (this.attendType[0] == 'duration') {
                if (!this.attendCourse) return this.$message.warning('请选择出勤/在线课程')
                const _start = this.courseDuration[0]
                const _end = this.courseDuration[1]
                if (!(_start >= 0 && _end >= 0) || _start >= _end) return this.$message.warning('出勤时间输入异常')

                params.courseAttend = { courseId: this.attendCourse, durations: [_start * 60 + 1, _end * 60] }
            } else if (this.attendType[0] === 'online') {
                params.courseAttend = { onlineStatus: this.onlineStatus, courseId: this.attendCourse }
            } else if (this.attendType[0] === 'dTime') {
                if (isEmptyArray(this.attendTimes)) return this.$message.warning('请选择时间范围')
                const _start = this.courseDuration[0]
                const _end = this.courseDuration[1]
                if (!(_start >= 0 && _end >= 0) || _start >= _end) return this.$message.warning('出勤时间输入异常')

                params.courseAttend = {
                    vType: this.attendCourseType,
                    attendTimes: this.attendTimes,
                    durations: [_start * 60 + 1, _end * 60],
                }
            } else if (this.attendType[0] === 'unAttend') {
                if (!this.attendCourse) return this.$message.warning('请选择出勤课程')
                params.courseAttend = { attendExist: false, courseId: this.attendCourse }
            }
            // 快递
            if (this.expressStatus) {
                params.express = { status: this.expressStatus }
            } else if (this.expressValue) {
                if (this.expressQuery === 'shopOrderNo') {
                    params.shopOrderNo = this.expressValue
                } else {
                    params.express = { [this.expressQuery]: this.expressValue }
                }
            }

            params = filterQueryEmpty(params)
            const res1 = await getOppoList(params)
            if (res1.data.list.length !== 0) {
                const _oppoIds = res1.data.list.map((i) => i.oppoId)
                const res2 = await oppoContactList({ oppoIds: _oppoIds })
                const _contactMap = {}
                res2.data.forEach((i) => {
                    if (!_contactMap[i.oppoId]) {
                        _contactMap[i.oppoId] = []
                    }
                    _contactMap[i.oppoId].push(i)
                })
                res1.data.list.forEach((item, index) => {
                    // 处理出勤数据
                    if (item.courseAttends) {
                        item.courseAttends.forEach((course) => {
                            course.durationText = getAttendDurationText(course.duration)
                        })
                    }
                    item.contactList = _contactMap[item.oppoId]
                    item.oppoIndex = index + 1

                    if (index + 1 === res1.data.list.length) {
                        item.lastOppo = true
                    }

                    // 判断是否可以转走
                    item.transferState = false
                    if (item.lastAssignTime && item.lastAssignTime.substr(0, 10) == dayjs().format('YYYY-MM-DD')) {
                        item.transferState = true
                    }
                })
            }
            this.tableData = res1.data.list
            this.page = res1.data.pageNum
            this.total = res1.data.total
        },
        getPulicCoursePackageListApi() {
            getPulicCoursePackageList({
                orderBy: '-id',
                page: 1,
                size: 100,
                ptypes: ['OPEN_LIVE_ROUND'],
                courseTimes: [dayjs().subtract(10, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
            }).then((res) => {
                const list = []
                res.data.forEach((item) => {
                    if (item.courses) {
                        const cList = []
                        let hasTodayCourse = false
                        item.courses.forEach((course) => {
                            const isToday = dayjs().isSame(dayjs(course.startTime), 'days')
                            if (isToday) hasTodayCourse = true
                            cList.push({
                                title: course.title,
                                id: course.id,
                                isToday,
                                time: course.startTime.substr(5, 11),
                            })
                        })
                        if (cList.length > 0) {
                            list.push({ title: item.title, pId: item.id, cList, hasTodayCourse })
                        }
                    }
                })
                this.attendCourseList = list
            })
        },
        getIntentTypeListApi() {
            getIntentTypeList({}).then((res) => {
                this.intentTypeList = res.data
                const _map = {}
                res.data.forEach((item) => {
                    _map[item.taskNo] = { name: item.taskName, color: generateColorFromString(item.taskNo) }
                })
                this.intentTypeMap = _map
            })
        },
        getStaffGroupData() {
            const params = {}
            if (!this.userInfo.isAdmin) params.manager = this.userInfo.id
            getStaffGroupList(params).then((res) => {
                this.manageGroupList = res.data
                this.getOppoListApi()
            })
        },
        getProviderCategoryDataApi() {
            getProviderCategoryData().then((res) => {
                this.providerList = res.providerList
                this.providerCascader = res.providerCascader
            })
        },
        getSelfFlag() {
            getSystemConfig({ func: 'oppo', key: 'self_transfer' }).then((res) => {
                this.selfFlag = res.data.self_transfer === 'true'
            })
        },
    },
}
</script>
<style lang="less" scoped>
.query_conditions {
    .condition_label {
        font-size: 12px !important;
        width: 60px;
        text-align: center;
        padding: 0;
    }
    /deep/.today_deal {
        display: flex;
        .el-checkbox-button__inner {
            margin-right: 8px;
        }
    }
    /deep/ .oppo_radio {
        .el-radio-button__inner {
            padding: 7px 0.8vw;
        }
    }
    .attend_course {
        width: 66.66% !important;
        max-width: inherit !important;
        .condition_content {
            display: flex;
        }
        .other_select {
            flex-shrink: 0;
            width: 120px;
        }
        .attend_duration {
            display: flex;
            align-items: center;
            .el-input-number {
                width: 90px;
            }
        }
    }
    .express {
        display: flex;
    }
}
.contact_list {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
        .el-icon-document-copy {
            display: block;
        }
    }
    .el-icon-document-copy {
        display: none;
        margin-left: 6px;
        font-size: 14px;
    }
}

/deep/ .course_list div {
    display: flex;
    span:nth-child(1) {
        width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 12px;
        cursor: pointer;
    }
}
</style>
<style lang="less" scoped>
@media screen and (min-width: 1921px) {
    .query_header {
        .query_conditions {
            .attend_course {
                width: 50% !important;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .query_header {
        .query_conditions {
            .attend_course {
                width: 100% !important;
            }
        }
    }
}
</style>
