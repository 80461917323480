<template>
    <el-select
        v-model="value"
        filterable
        :size="size || ''"
        placeholder="请选择项目"
        :disabled="disabled || false"
        @change="onChange"
    >
        <el-option
            v-for="item in projectList"
            :key="item.projectNo"
            :label="item.projectName"
            :value="item.projectNo"
        />
    </el-select>
</template>
<script>
import { mapGetters } from 'vuex'

const _localStorageKey = 'ProjectSelect-projectNo'
export default {
    computed: { ...mapGetters(['projectList']) },
    props: ['defaultValue', 'disabled', 'size'],
    data() {
        return {
            value: '',
        }
    },
    watch: {
        projectList: function () {
            this.initProjectList()
        },
    },
    created() {
        this.value = this.defaultValue
        this.initProjectList()
    },
    methods: {
        onChange(val) {
            this.value = val
            this.$emit('change', val)
            window.localStorage.setItem(_localStorageKey, val)
        },
        initProjectList() {
            if (!this.value && this.projectList.length !== 0) {
                const _localStorageVal = localStorage.getItem(_localStorageKey)
                let _val = ''
                this.projectList.forEach((item) => {
                    if (item.projectNo === _localStorageVal) {
                        _val = _localStorageVal
                    }
                })
                this.onChange(_val || this.projectList[0]['projectNo'])
            }
            this.$emit('projectList', this.projectList)
        },
    },
}
</script>
